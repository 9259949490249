const setActivitiesList = data => ({
  type: "SET_ACTIVITIES_LIST",
  data
});

const setLockedActivities = data => ({
  type: "SET_LOCKED_ACTIVITIES",
  data
});

const setUnLockedActivities = data => ({
  type: "SET_UNLOCKED_ACTIVITIES",
  data
})

const setReadingList = data => ({
  type: 'SET_READING_LIST',
  data
})

const setActivityDetail = data => ({
  type: 'SET_ACTIVITY_DETAIL',
  data
})

const setActivityQuestionsList = data => ({
  type: 'SET_ACTIVITY_QUESTIONS_LIST',
  data
})

const setActivitiesLoading = data => ({
  type: 'SET_ACTIVITIES_LOADING',
  data
})

export const mutation = {
  setActivitiesList,
  setLockedActivities,
  setUnLockedActivities,
  setReadingList,
  setActivityDetail,
  setActivityQuestionsList,
  setActivitiesLoading
};
