import React, { useState } from 'react'
import { useEffect } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from "store/user/actions";
import * as homeActions from "store/home/actions";
import { storage as LocalStorage } from "services/config/storage";
import Loader from "components/Loader/Loader";
import Header from "../../components/Header";
import { Analytics } from "services/analytics";
import store from "../../store";

import './B2BLogin.scss'

const B2BLogin = ({ b2bUserLoginRequest, history, location, startSessionTime, callSessionSet }) => {

    let [error, setError] = useState(null)

    let paramUrl = new URLSearchParams(location.search);
    let userData = paramUrl.get("user_data");

    const logoutUser = async () => {
        try{
            const sessionObj = {
                started_at: startSessionTime ? new Date(startSessionTime)
                    : localStorage.getItem("sessionStart") ? new Date(JSON.parse(localStorage.getItem("sessionStart"))):new Date(),
                ended_at: new Date(),
            };
            await callSessionSet(sessionObj);

            Analytics.onlogOut({
                start: sessionObj.started_at,
                end: sessionObj.ended_at,
            });
            LocalStorage.destroy.all();
            store.dispatch({ type: "RESET_STATE" });

            return true
        }
        catch{
            return false
        }
    };

    useEffect(() => {
        let loginFunc = async () => {
            let payload = {
                user_data: userData
            }
            let res = await b2bUserLoginRequest(payload)
            if (!res?.result?.auth_token) {
                setError(`Unable to process your request at the moment. Please contact your administrator.`)
            }else{
                Analytics.onLogin()
                history.push('/')
            }
        }

        if (userData) {
            if (LocalStorage.fetch.defaultChild()?.id) {
                logoutUser().then((res) => {
                    console.log(res)
                    if (res) {
                        loginFunc()
                    }
                })
            } else {
                loginFunc()
            }
        }

    }, [])


    return (
        <div className='b2b_login_main_container'>
            <Header />
            <div className='b2b_login_container'>
                {
                    error ?
                        <div className='error_container'>
                            {error}
                        </div>
                        :
                        <div className='loading_container'>
                            <Loader />
                        </div>
                }
            </div>
        </div>
    )
}

const connectedComponent = connect(
    ({ user, home }) => ({ ...user, ...home }),
    (dispatch) => bindActionCreators({ ...userActions, ...homeActions }, dispatch)
)(B2BLogin);

export default connectedComponent;