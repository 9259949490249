import { initialState } from "./state";

export const activities = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ACTIVITIES_LIST": {
      return Object.assign({}, state, {
        activitiesList: action.data,
      });
    }
    case 'SET_LOCKED_ACTIVITIES': {
      return {
        ...state,
        lockedActivitiesList: action.data
      }
    }
    case 'SET_UNLOCKED_ACTIVITIES': {
      return {
        ...state,
        unlockedActivitiesList: action.data
      }
    }
    case 'SET_READING_LIST': {
      return {
        ...state,
        readingList: action.data
      }
    }
    case 'SET_ACTIVITY_DETAIL': {
      return {
        ...state,
        activeActivity: action.data
      }
    }
    case 'SET_ACTIVITY_QUESTIONS_LIST': {
      return {
        ...state,
        activeActivityQuestionsList: action.data.result
      }
    }
    case 'SET_ACTIVITIES_LOADING':{
      return{
        ...state,
        loading:action.data
      }
    }
    default:
      return state;
  }
};
